<template>
  <v-col
    cols="12"
    class="px-4 py-4"
  >
    <v-card>
      <v-col
        cols="12"
        class="  mx-1"
      >
        <v-row class="px-4">
          <v-spacer />
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="4"
            xl="4"
            class="pr-3"
          >
            <div id="textrotate">
              <span>
                <p>( {{ title }} )</p></span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            lg="2"
            xl="2"
            class="pr-3"
          >
            <v-select
              v-model="santralModel"
              :items="santralDataList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="3"
            md="3"
            lg="2"
            xl="2"
            class="pr-3"
          >
            <v-select
              v-model="measureSelect"
              :items="measures"
              label=""
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="2"
            md="2"
            lg="1"
            xl="1"
            class="pr-3"
          >
            <v-btn
              color="primary"
              @click="getData"
            >
              Filtrele
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <div
          id="heatmapsHourly"
          style="height: 300px; padding: 1px"
        />
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import * as Heatmap from '@/api/Heatmap/Heatmap';
import moment from 'moment';
import i18n from '@/locale/index';
import * as echarts from 'echarts';
import { getSantralMinute } from '@/api/Request/santral';

export default {
  name: 'Dashboard',

  data: () => ({

    menu: false,
    modal: false,
    menu2: false,
    mina: '',
    maxa: '',
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),
    menu1: false,
    minDate: '',
    daterangetext: i18n.t('Tarih Seç'),
    selecteditemstext: '',
    mindate: '2010',
    all: i18n.t('All'),
    dateRules: [(v) => !!v || 'Date is required'],
    dates: [],
    inverterList: {},
    measureList: [],
    santral_items: [],
    startDate: moment().add(-6, 'day').format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    measures: [],
    measureSelect: 'IstantaneousPower',
    componentKey: 1,
    title: '',
    santralModel: '',
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      const { santral } = this.$store.getters.selectedSantralModel;
      this.santralModel = santral;
      return santralDataList;
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (this.santralModel !== null && this.santralModel !== undefined) {
        this.getData();
      }
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;
    let k = 0;
    function myTimer1() {
      // eslint-disable-next-line no-plusplus
      k++;

      if (k > 30) {
        clearInterval(myVar1);
      }
      const { statsmeasureList } = self.$store.getters;

      if (
        statsmeasureList !== undefined
        && Object.keys(statsmeasureList).length > 0
      ) {
        clearInterval(myVar1);
        const measuresList = [];
        Object.keys(statsmeasureList).forEach((k) => {
          const obj = statsmeasureList[k];
          obj.text = i18n.t(obj.value);
          measuresList.push(obj);
        });
        self.measures = measuresList;
        self.getData();
      }
    }
  },

  methods: {
    getData() {
      this.title = `${moment()
        .add(-6, 'day')
        .format('YYYY-MM-DD')} / ${moment().format('YYYY-MM-DD')} ${i18n.t(
        this.measureSelect,
      )}`;

      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: this.measureSelect,
        },
      };

      const chartDom = document.getElementById('heatmapsHourly');
      const myChart = echarts.init(chartDom);
      const tempdata = getSantralMinute(formdata);

      tempdata.then((_rawdata) => {
        let sMeasure;
        this.measures.forEach((v) => {
          if (this.measureSelect === v.value) {
            sMeasure = v;
          }
        });

        Heatmap.default.heatmapWeeklyHour(
          _rawdata,
          sMeasure,
          myChart,
          this.finishDate,
        );
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },

    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format('YYYY-MM-DD');
      this.startDate = moment(daterange[0]._d).format('YYYY-MM-DD');
      const alrt = this.select_filter();

      if (alrt != undefined) {
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t('Date Range');
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t('Date Range')} ${i18n.t(
        this.selectcolumn,
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
